<template>
  <v-app>
    <v-main>
      <div v-if="debugMode" class="debug-badge">Debug Mode</div>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      setting: (state) => state.setting.data,
    }),
    props:[
      //
    ],
    data: () => ({
      debugMode: false,
    }),
    created() {
      //
    },
    mounted() {
      if(this.$route.query.debug && this.$route.query.debug == '1'){
      this.$store.commit("updateSetting", {debug : true});
    }else if(this.$route.query.debug && this.$route.query.debug != '1') {
      this.$store.commit("updateSetting", {debug : false});
    }
  
    this.debugMode = this.setting.debug
    },
    methods: {
      //
    }
  };
</script>
<style scoped>
.debug-badge {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 5px;
  font-size: 12px;
  z-index: 9999;
}
</style>